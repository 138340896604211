import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import AnchorLinks from 'components/Button/AnchorLink';

import { Props } from 'types/types';

const Container = styled.div<Props>`
  position: relative;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  filter: ${(props) => (props.filters ? 'invert(0%)' : 'invert(100%)')};

  & > a {
    font-size: 3rem;

    &:hover {
      color: ${({ theme: { colors } }) => colors.white};
    }
  }
`;

const Logo = ({ filters }: any) => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          sites {
            title
            linkTo
          }
        }
      }
    }
  `);

  const { title, linkTo } = datas.markdownRemark.frontmatter.sites;

  return (
    <Container filters={filters}>
      <AnchorLinks to={linkTo}>{title}</AnchorLinks>
    </Container>
  );
};

export default Logo;

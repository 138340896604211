import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { Props } from 'types/types';

const Container = styled.div<Props>`
  width: 8rem;
  height: fit-content;
  margin-top: 0.35rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: ${(props) => (props.filters ? `invert(100%)` : `invert(0)`)};
`;

const Image = styled.img`
  width: 1.7rem;
  height: 1.7rem;

  &:last-of-type {
    width: 1.8rem;
    height: 1.8rem;
  }
`;

const Link = styled.a`
  margin-bottom: 0;
`;

interface Social {
  id: number;
  title: string;
  url: string;
  images: {
    src: {
      publicURL: string;
    };
    alt: string;
  };
}

const Social = () => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/site/" } }) {
        edges {
          node {
            frontmatter {
              socials {
                id
                title
                url
                images {
                  src {
                    publicURL
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  `);

  const socialDatas = datas.allMarkdownRemark.edges[0];

  const socialData = socialDatas.node.frontmatter.socials.map(
    ({ id, title, url, images }: Social) => (
      <Link key={id} href={url} title={title}>
        <Image src={images.src.publicURL} alt={images.alt} />
      </Link>
    ),
  );

  return <Container filters={true}>{socialData}</Container>;
};

export default Social;

import React from 'react';
import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Link = styled(AnchorLink)`
  width: fit-content;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover {
    color: ${({ theme: { colors } }) => colors.mint};
  }
`;

interface Link {
  to: string;
  children: any;
}

const AnchorLinks = ({ to, children }: Link) => {
  return (
    <Link to={to} stripHash>
      {children}
    </Link>
  );
};

export default AnchorLinks;

import styled from 'styled-components';
import { Props } from 'types/types';

import AnchorLinks from 'components/Button/AnchorLink';

import { FlexCenter } from 'styles/_mixins';
import { maxScreen } from 'styles/_breakpoints';

export const Container = styled.section<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: fit-content;
  background: ${(props) =>
    props.backgrounds ? props.theme.colors.black : 'transparent'};
  transition: 0.5s ease;
`;

export const NavMenu = styled.nav`
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
`;

export const NavList = styled.ul<Props>`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${maxScreen.tablet} {
    ${FlexCenter}
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    margin: 0;
    background: ${({ theme: { background } }) => background.white};
    visibility: ${({ click }) => (click ? 'visible' : 'hidden')};
    opacity: ${({ click }) => (click ? '1' : '0')};
    filter: ${(props) => (props.filters ? 'invert(100%)' : 'invert(0%)')};
    transition: all 0.5s ease;
  }
`;

export const NavItems = styled.li<Props>`
  margin-right: 5rem;
  text-transform: capitalize;

  &:last-of-type {
    margin-right: 0;
  }

  @media ${maxScreen.tablet} {
    margin: 0;
    padding-bottom: 3rem;

    & > a {
      font-size: 2rem;
      text-transform: uppercase;
      color: ${({ theme: { colors } }) => colors.black};

      &:focus {
        outline: none;
      }
    }
  }
`;

export const NavLink = styled(AnchorLinks)`
  font-family: inherit;
`;

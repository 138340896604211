import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useMenuContext } from 'hooks/menu-context';

import Logo from 'components/Logo/Logo';
import Hamburger from 'components/Header/Hamburger';

import { Container, NavMenu, NavList, NavItems, NavLink } from './_nav';
import { Wrapper } from 'styles/_mixins';

type NavProps = {
  backgrounds?: string;
  session?: string;
};

interface Nav {
  id: number;
  title: string;
  url: string;
}

const Nav: React.FC<NavProps> = ({ backgrounds }) => {
  const { showNav, setShowNav, clickMenu } = useMenuContext();
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/site/" } }) {
        edges {
          node {
            frontmatter {
              nav {
                id
                title
                url
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    window.addEventListener('scroll', navBackground);
    return () => window.removeEventListener('scroll', navBackground);
  });

  const navBackground = () => {
    window.scrollY > 0 ? setShowNav(true) : setShowNav(false);
  };

  const data = datas.allMarkdownRemark.edges[0];

  const navDatas = data.node.frontmatter.nav.map(({ id, title, url }: Nav) => (
    <NavItems key={id}>
      <NavLink to={url}>{title}</NavLink>
    </NavItems>
  ));

  return (
    <Container backgrounds={showNav ? '#33333372' : backgrounds}>
      <Wrapper>
        <NavMenu>
          <Logo filters />
          <Hamburger />
          <NavList click={clickMenu} filters={clickMenu}>
            {navDatas}
          </NavList>
        </NavMenu>
      </Wrapper>
    </Container>
  );
};

export default Nav;

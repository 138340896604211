export const breakponts = {
  phone: 320,
  smart: 376,
  smland: 540,
  tablet: 768,
  desktop: 992,
  land: 1280,
  full: 1920,
  wide: 2550,
};

export const screen = {
  phone: `${breakponts.phone}px`,
  smart: `${breakponts.smart}px`,
  smland: `${breakponts.smland}px`,
  tablet: `${breakponts.tablet}px`,
  desktop: `${breakponts.desktop}px`,
  land: `${breakponts.land}px`,
  full: `${breakponts.full}px`,
  wide: `${breakponts.full}px`,
};

export const minScreen = {
  phone: `(min-width: ${screen.phone})`,
  smart: `(min-width: ${screen.smart})`,
  smland: `(min-width: ${screen.smland})`,
  tablet: `(min-width: ${screen.tablet})`,
  desktop: `(min-width: ${screen.desktop})`,
  land: `(min-width: ${screen.land})`,
  full: `(min-width: ${screen.full})`,
  wide: `(min-width: ${screen.wide})`,
};

export const maxScreen = {
  phone: `(max-width: ${screen.phone})`,
  smart: `(max-width: ${screen.smart})`,
  smland: `(max-width: ${screen.smland})`,
  tablet: `(max-width: ${screen.tablet})`,
  desktop: `(max-width: ${screen.desktop})`,
  land: `(max-width: ${screen.land})`,
  full: `(max-width: ${screen.full})`,
  wide: `(max-width: ${screen.wide})`,
};

export const deviceScreen = {
  smart: `(min-width: ${screen.phone}) and (max-width: ${screen.smart})`,
  smland: `(min-width: ${screen.phone}) and (max-width: ${screen.smland})`,
  tablet: `(min-width: ${screen.smland}) and (max-width: ${screen.tablet})`,
  desktop: `(min-width: ${screen.tablet}) and (max-width: ${screen.desktop})`,
  land: `(min-width: ${screen.desktop}) and (max-width: ${screen.land})`,
  full: `(min-width: ${screen.land}) and (max-width: ${screen.full})`,
  wide: `(min-width: ${screen.full}) and (max-width: ${screen.wide})`,
};

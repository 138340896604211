import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Social from 'components/Social/Social';

import { Wrapper } from 'styles/_mixins';

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 1.5rem 0;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Copy = styled.p`
  margin-bottom: 0;
  font-size: 1.2rem;
  color: ${({ theme: { colors } }) => colors.white};
`;

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { title } = site.siteMetadata;

  return (
    <Container>
      <Wrapper>
        <FlexBox>
          <Copy>{`${title} © ${new Date().getFullYear()}, Built by JT `}</Copy>
          <Social />
        </FlexBox>
      </Wrapper>
    </Container>
  );
};

export default Footer;

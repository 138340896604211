import React from 'react';

import { ThemeProvider } from 'styled-components';
import bg from 'assets/images/pattern.png';
/* Background pattern from Toptal Subtle Patterns */

export const theme = {
  colors: {
    white: '#e2e2e2',
    black: '#111111',
    opblack: '#24242488',
    red: '#dc3546',
    gray: '#bababa',
    lightgray: '#e8e8e8',
    darkgray: '#4c464d',
    green: '#41b883',
    mint: '#57ABAB',
    darkmint: '#2f8484',
    lightwhite: '#efefef5e',
    offwhite: '#fdfdfd',
  },
  fonts: {
    roboto: "'Roboto', sans-serif",
    montserrat: "'Montserrat', sans-serif",
    oswald: "'Oswald', sans-serif",
    ebgaramond: "'EB Garamond', serif",
  },
  background: {
    white: '#f9f9f9',
    opblack50: '#33333350',
    pattern: `url(${bg})`,
    google: '#4c8bf5',
  },
  filter: {
    mint: `invert(59%) sepia(74%) saturate(234%) hue-rotate(131deg) brightness(89%) contrast(96%)`,
    darkmint: `invert(59%) sepia(74%) saturate(234%) hue-rotate(131deg) brightness(79%) contrast(96%)`,
  },
};

const Theme = ({ children }: any) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
